import {Button, message, Upload, Input, Modal, Pagination, Empty} from "antd";
import {FileOutlined, FolderOutlined, InboxOutlined, ReloadOutlined, UploadOutlined} from "@ant-design/icons";
import Content from "../../config/content";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {getDeleteCode, getDownloadCode, getNetDiskList, netDiskOperate} from "../../config/api";

const NetDisk = () => {

    const [data, setData] = useState([])
    const [path, setPath] = useState([""])
    const [location, setLocation] = useState([""])
    const [createFolderDialog, setCreateFolderDialog] = useState(false)
    const [uploadDialog, setUploadDialog] = useState(false)

    useEffect(() => {
        refreshData()
    }, [])

    const refreshData = (req) => {
        let queryPath = ""
        if (req !== undefined) {
            queryPath = req
        } else {
            queryPath = path[path.length - 1]
        }
        getNetDiskList({path: queryPath}).then(r => {
            setData(r?.fileList ? r?.fileList : [])
        })
    }
    
    const createFolder = () => {
        setCreateFolderDialog(true)
    }

    const download = (name) => {
        getDownloadCode({path: path + name, name}).then(r => {
            window.location.href = Content.fileServiceUrl + "/api/file/download?code=" + r
            
            
            const link = document.createElement('a');
            link.href = Content.fileServiceUrl + "/api/file/download?code=" + r;
            link.download = "cccc.png";
            // 这对于非同源URL可能不会设置filename，因为出于安全考虑，浏览器限制了这个功能。
            document.body.appendChild(link); // 必须将链接添加到DOM中
            link.click(); // 模拟点击
            document.body.removeChild(link); // 然后移除
            
        })
    }

    const deleteFile = (name) => {
        getDeleteCode({path: name, name}).then(r => {
            refreshData()
            message.success("删除成功").then()
        })
    }

    const share = (name) => {
        getDownloadCode({path: path + name, name}).then(r => {
            let tmpInput = document.createElement("input");
            document.body.appendChild(tmpInput);
            tmpInput.value = Content.fileServiceUrl + "/api/file/download?code=" + r
            tmpInput.select();
            document.execCommand("copy");
            tmpInput.remove();
            message.success("复制成功").then()
        })
    }

    return (
        <div style={{display: "flex", flexDirection: "column", gap: 20}}>
            <div className="card" style={{display: "flex", flexDirection: "row", alignItems: "center", gap: 10}}>
                <div style={{fontSize: 16, fontWeight: 800, whiteSpace: "nowrap", flex: 1}}>企业云盘</div>
                <Button onClick={createFolder}>新建文件夹</Button>
                {/*<Upload*/}
                {/*    action={Content.fileServiceUrl + "/api/file/upload"}*/}
                {/*    multiple*/}
                {/*    // fileList={uploadList}*/}
                {/*    onChange={() => refreshData()}*/}
                {/*    data={(e) => {*/}
                {/*        return {path: "/" + e.name, userId: userInfo.id}*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Button icon={<UploadOutlined />} type="primary">上传</Button>*/}
                {/*</Upload>*/}
                <Button icon={<UploadOutlined />} type="primary" onClick={() => setUploadDialog(true)}>上传</Button>
            </div>
            <div className="card" style={{borderRadius: 15}}>
                <div style={{borderRadius: "15px 15px 0 0", backgroundColor: Content.primaryColor_0_1, display: "flex", alignItems: "center"}}>
                    <div style={{flex: 1}}>
                        当前目录：
                        {location.join("/")}
                        <ReloadOutlined style={{marginLeft: 10, color: "gray"}} onClick={refreshData}/>
                    </div>
                    <Input.Search placeholder="查询文件" style={{ width: 193 }} />
                </div>
                <div className="div-button" style={{padding: "8px 16px", display: path[path.length - 1] !== "" ? "flex" : "none"}} onClick={() => {
                    let backPath = path[path.length - 2]
                    setPath(path.slice(0, -1))
                    setLocation(location.slice(0, -1))
                    refreshData(backPath)
                }}>
                    {/*<FolderOutlined style={{cursor: "pointer", color: Content.primaryColor, }}/>*/}
                    <span>...</span>
                </div>
                {data.map((item, index) => {
                    return (
                        <div className="div-button" key={index} style={{padding: "8px 16px", display: "flex"}} onClick={() => {
                            if (item.type === "folder") {
                                setPath([...path, item.id])
                                setLocation([...location, item.name])
                                refreshData(item.id)
                            }
                        }}>
                            <div style={{flex: 1}}>
                                <div>
                                    <FolderOutlined style={{cursor: "pointer", color: Content.primaryColor, marginRight: 5, display: item.type === "folder" ? null : "none"}}/>
                                    <span style={{cursor: "pointer"}}>{item.name}</span>
                                </div>
                            </div>
                            {/*<div style={{marginLeft: 10}}>235MB</div>*/}
                            {/*<div style={{marginLeft: 20}}>2023/06/08 12:07:39</div>*/}
                            <div style={{display: "flex", gap: 10, color: "#aaa"}}>

                                <div className="text-button">
                                    重命名
                                </div>
                                <div className="text-button" style={{display: item.type === "folder" ? "none" : null}} onClick={() => download(item.name)}>
                                    下载
                                </div>

                                <div className="text-button" style={{display: item.type === "folder" ? "none" : null}} onClick={() => share(item.name)}>
                                    分享
                                </div>

                                <div onClick={ () => deleteFile(item.name) } className="text-button">
                                    删除
                                </div>

                            </div>
                        </div>
                    )
                })}

                {data.length === 0 ? <Empty style={{padding: "40px 0"}}/> : null}

                <div style={{borderRadius: "0 0 15px 15px", display: "flex"}}>
                    <div style={{flex: 1}}>使用容量: 132MB / 2048GB</div>
                    <Pagination size="small" defaultCurrent={1} total={data.length} hideOnSinglePage/>
                </div>

            </div>
            <Modal title="新建文件夹" open={createFolderDialog}
                   onCancel={() => {
                       setCreateFolderDialog(false)
                       refreshData()
                   }}
                   destroyOnClose
                   onOk={() => {
                       setCreateFolderDialog(false)
                       netDiskOperate({
                           type: "createFolder",
                           name: document.getElementById("createFolderInput").value,
                           path: path[path.length - 1]
                       }).then(r => {
                           console.log(r)
                           refreshData()
                           message.success("Success").then()
                       })
                   }}
            >
                <Input id="createFolderInput"/>
                
            </Modal>
            <Modal title="上传" open={uploadDialog} footer={null}
                   onCancel={() => {
                       setUploadDialog(false)
                       refreshData()
                   }}
                   destroyOnClose
            >
                <div style={{display: "flex", gap: 10}}>
                    <Upload
                        action="/api/file/upload"
                        headers={{"X-Access-Token": localStorage.getItem("X-Access-Token") ?? ""}}
                        data={(e) => {
                            return {
                                fileName: e.name,
                                path: path[path.length - 1]
                            }
                        }}
                        multiple
                    >
                        <Button icon={<FileOutlined />} type="primary">文件上传(可多选)</Button>
                    </Upload>
                    <Upload
                        action="/api/file/upload"
                        headers={{"X-Access-Token": localStorage.getItem("X-Access-Token") ?? ""}}
                        data={(e) => {
                            return {
                                fileName: e.name,
                                path: "/" + e.webkitRelativePath.replace(e.name, "")
                            }
                        }}
                        directory
                        multiple
                    >
                        <Button icon={<FolderOutlined />}>文件夹上传</Button>
                    </Upload>
                </div>
                <Upload.Dragger
                    style={{marginTop: 10}}
                    action="/api/file/upload"
                    headers={{"X-Access-Token": localStorage.getItem("X-Access-Token") ?? ""}}
                    data={(e) => {
                        e.crossOrigin = "anonymous"
                        return {
                            fileName: e.name,
                            path: path[path.length - 1]
                        }
                    }}
                    multiple
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p >
                    <p className="ant-upload-text">点击或拖动文件至此处上传</p >
                    <p className="ant-upload-hint">支持多文件上传，文件夹请使用上传文件夹</p >
                </Upload.Dragger>
            </Modal>
        </div>
    )
}

export default NetDisk;

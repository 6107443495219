import React from 'react';
import {Dropdown} from "antd";
import {LogoutOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import {doLogout} from "../../../config/request";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Content from "../../../config/content"
import Avatar from "../../../assist/avatar/大象.svg"

const Header = ({menuStatusChange}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {userInfo} = useSelector(state => state.user);

    const logout = () => {
        doLogout(dispatch).then(_ => navigate("/login"))
    }

    return (
        <>
            <div className="header flex align-center">

                <MenuUnfoldOutlined
                    className="menu-icon"
                    onClick={() => {
                        menuStatusChange(true)
                    }}
                />
                <div className="flex clickable" onClick={() => navigate("/")}>
                    <img src={Content.logoUrl} alt="" height={32}/>
                    <div className="title">{Content.title}</div>
                </div>
                <div className="flex-1"></div>
                <Dropdown menu={{
                    items: [
                        {
                            key: '1',
                            label: (
                                <div onClick={logout}>
                                    <LogoutOutlined /> 退出登录
                                </div>
                            ),
                        }
                    ]
                }}>
                    <div className="clickable flex align-center gap-10">
                        {
                            userInfo.nickName ?
                                <img src={Avatar} alt="" height={40} className="round"/> :
                                null
                        }
                        <div>{userInfo.nickName}</div>
                    </div>
                </Dropdown>
            </div>
        </>
    )
}

export default Header;

import {Drawer} from "antd";
import Content from "../../../config/content";
import React, {useEffect, useState} from "react";
import {LeftCircleOutlined, MenuFoldOutlined, RightCircleOutlined} from "@ant-design/icons";
import {useLocation, useNavigate} from "react-router-dom";
import {routes} from "../../../config/routes";

const Sider = ({clintWidth, menuStatus, menuStatusChange}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [miniStyle, setMiniStyle] = useState(false);
    const [menuList, setMenuList] = useState([])

    useEffect(() => {
        setMenuList(routes[0].children.map((item, index) => {
            return {
                key: index,
                label: item.label,
                path: item.path,
                icon:item.icon
            }
        }).slice(1,-1))
    }, [])

    useEffect(() => {
        setMiniStyle(clintWidth < 1200)
    }, [clintWidth])

    const MenuList = () => {
        return (
            <div className="flex gap-5 flex-column">
                {menuList.map((item, index) =>
                    <div
                        className={"sider-button" + (location.pathname === item.path ? " sider-button-active" : "")}
                        key={index}
                        onClick={() => {
                            menuStatusChange(false)
                            navigate(item.path)
                        }}
                    >
                        {item.icon ? <div className="font-14">{item.icon}</div> : <div className="menu-list-icon-empty"></div>}
                        {!miniStyle || menuStatus ? <div className="text-ellipsis">{item.label}</div> : null}
                    </div>
                )}
            </div>
        )
    }

    return (
        <>
            <Drawer
                open={menuStatus}
                onClose={() => menuStatusChange(false)}
                placement="left"
                closable={false}
                width={260}
                bodyStyle={{padding: 0}}
            >
                <div className="flex flex-column height-100">
                    <div className="menu-background"></div>
                    <div className="sider-drawer-header flex align-center">
                        <MenuFoldOutlined className="menu-icon" onClick={() => menuStatusChange(false)}/>
                        <img src={Content.logoUrl} alt="" height={32}/>
                        <div className="title">{Content.title}</div>
                    </div>
                    <div className="sider-drawer">
                        <MenuList/>
                    </div>
                </div>
            </Drawer>
            <div className={"sider" + (miniStyle ? " sider-mini" : " sider-normal")}>
                <div className="menu-background"></div>
                <MenuList/>
                <div className="shrink" onClick={() => setMiniStyle(!miniStyle)}>
                    {miniStyle ?
                        <RightCircleOutlined className="shrink-button"/> :
                        <LeftCircleOutlined className="shrink-button"/>}
                </div>
            </div>

        </>
    )
}

export default Sider;

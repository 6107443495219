import React, {useEffect, useState} from 'react';
import Header from "./component/header";
import {Outlet, useNavigate} from "react-router-dom";
import Footer from "./component/footer";
import Sider from "./component/sider";
import {useDispatch} from "react-redux";
import {doCheck} from "../../config/request";

const LayoutPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [menuStatus, setMenuStatus] = useState(false);
    const [clintWidth, setClintWidth] = useState(window.innerWidth)

    useEffect(() => {
        if (localStorage.getItem("X-Access-Token")) {
            doCheck(dispatch).then(r => {
                if (r) {

                } else {
                    navigate("/login")
                }
            })
        } else {
            navigate("/login")
        }
        window.addEventListener("resize", () => {
            setClintWidth(window.innerWidth)
        })
    }, []);

    return (
        <div className="bg">
            <Header clintWidth={clintWidth} menuStatusChange={(status) => setMenuStatus(status)} />
            <div className="flex">
                <Sider clintWidth={clintWidth} menuStatus={menuStatus} menuStatusChange={(status) => setMenuStatus(status)} />
                <div className="content flex flex-column">
                    <div className="flex-1">
                        <Outlet />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default LayoutPage;

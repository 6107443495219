import React, {useEffect, useState} from "react";

function Home() {

    const [date, setDate] = useState(new Date())

    useEffect(() => {
        setInterval(() => {
            setDate(new Date())
        }, 1000)
    }, [])

    return (
        <div style={{display: "flex", flexDirection: "column", gap: 20}}>
            <div className="card">
                欢迎回来 ! {date.toLocaleString()}
            </div>
        </div>
    );
}

export default Home;

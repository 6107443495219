import React, {useEffect, useState} from "react";
import {getClockRecord, getUserList} from "../../config/api";
import {Cascader, DatePicker, Input, InputNumber} from "antd";
import dayjs from "dayjs";

const options = [
    {
        value: '上海',
        label: '上海',
    },
    {
        value: '湖北',
        label: '湖北',
        children: [
            {
                value: '武汉',
                label: '武汉',
            },
            {
                value: '黄石',
                label: '黄石',
            },
            {
                value: '十堰',
                label: '十堰',
            },
            {
                value: '宜昌',
                label: '宜昌',
            },
            {
                value: '襄阳',
                label: '襄阳',
            },
            {
                value: '鄂州',
                label: '鄂州',
            },
            {
                value: '荆门',
                label: '荆门',
            },
            {
                value: '孝感',
                label: '孝感',
            },
            {
                value: '荆州',
                label: '荆州',
            },
            {
                value: '黄冈',
                label: '黄冈',
            },
            {
                value: '咸宁',
                label: '咸宁',
            },
            {
                value: '随州',
                label: '随州',
            },
            {
                value: '恩施苗自',
                label: '恩施苗自',
            },

        ],
    },
    {
        value: '湖南',
        label: '湖南',
        children: [
            {
                value: '长沙',
                label: '长沙',
            },
            {
                value: '株洲',
                label: '株洲',
            },
            {
                value: '湘潭',
                label: '湘潭',
            },
            {
                value: '衡阳',
                label: '衡阳',
            },
            {
                value: '邵阳',
                label: '邵阳',
            },
            {
                value: '岳阳',
                label: '岳阳',
            },
            {
                value: '常德',
                label: '常德',
            },
            {
                value: '张家界',
                label: '张家界',
            },
            {
                value: '益阳',
                label: '益阳',
            },
            {
                value: '郴州',
                label: '郴州',
            },
            {
                value: '永州',
                label: '永州',
            },
            {
                value: '怀化',
                label: '怀化',
            },
            {
                value: '娄底',
                label: '娄底',
            },
            {
                value: '湘西苗自',
                label: '湘西苗自',
            },

        ],
    },
];

// const dateFormat = 'YYYY-MM-DD';

const trainingIdList = [
    {value: '10021', label: <div style={{display: "flex", gap: 10}}><div style={{width: 50}}>10021</div><div style={{width: 50}}>苏州</div><div>两两八月堂训</div></div>},
    {value: '10013', label: <div style={{display: "flex", gap: 10}}><div style={{width: 50}}>10013</div><div style={{width: 50}}>上海</div><div>两两六月堂训</div></div>},
    {value: '10012', label: <div style={{display: "flex", gap: 10}}><div style={{width: 50}}>10012</div><div style={{width: 50}}>长沙</div><div>两两六月堂训</div></div>},
    {value: '10011', label: <div style={{display: "flex", gap: 10}}><div style={{width: 50}}>10011</div><div style={{width: 50}}>武汉</div><div>两两六月堂训</div></div>},
]

const TrainingClock = () => {

    const [userList, setUserList] = useState([])
    // const [date, setDate] = useState(dayjs(new Date()))
    // const [location, setLocation] = useState(["上海"])
    const [trainingId, setTrainingId] = useState([trainingIdList[0].value])

    useEffect(() => {
        getClockRecord({value: trainingId[0]}).then(r => {
            setUserList(r)
        })
    }, [trainingId])

    return (
        <div style={{display: "flex", flexDirection: "column", gap: 20}}>
            <div className="card">
                <div style={{display: "flex", gap: 20, flexWrap: "wrap", alignItems: "center"}}>
                    {/*<div style={{flex: 1, minWidth: 250, display: "flex", alignItems: "center"}}>*/}
                    {/*    <div style={{width: 70, textAlign: "right"}}>日期：</div>*/}
                    {/*    <DatePicker style={{flex: 1}} onChange={(date) => setDate(date)} defaultValue={dayjs(new Date())}/>*/}
                    {/*</div>*/}
                    {/*<div style={{flex: 1, minWidth: 250, display: "flex", alignItems: "center"}}>*/}
                    {/*    <div style={{width: 70, textAlign: "right"}}>地点：</div>*/}
                    {/*    <Cascader style={{flex: 1}} options={options} placeholder="请选择堂训地点" onChange={(e) => setLocation(e)} value={location}/>*/}
                    {/*</div>*/}
                    {/*<div style={{flex: 1, minWidth: 250, display: "flex", alignItems: "center"}}>*/}
                    {/*    <div style={{width: 70, textAlign: "right"}}>堂训编号：</div>*/}
                    {/*    <Input style={{flex: 1}} placeholder="输入堂训编号" value={trainingId} onChange={(e) => setTrainingId(e)}/>*/}
                    {/*</div>*/}
                    <div style={{width: 70, textAlign: "right"}}>堂训编号：</div>
                    <Cascader style={{flex: 1}} options={trainingIdList} placeholder="请选择堂训地点" onChange={(e) => setTrainingId(e)} value={trainingId}/>
                </div>
            </div>
            <div className="card">
                <div style={{display: "flex", alignItems: "end"}}>
                    <div style={{fontSize: 18, fontWeight: 800, whiteSpace: "nowrap", flex: 1}}>已签到名单</div>
                    <div style={{whiteSpace: "nowrap"}}>总计: {userList?.length} 人</div>
                </div>
                <div style={{fontSize: 16,display: "flex", flexDirection: "column", gap: 5}}>
                    {userList?.map((item, index) =>
                        <div key={index} style={{display: "flex"}}>
                            <div style={{flex: 1}}>
                                {index + 1}. {item?.realName}
                            </div>
                            <div style={{color: "gray"}}>{item?.department.slice(0, -6)}</div>
                            <div style={{color: "gray", marginLeft: 20}}>{item?.clockDate.slice(11)}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TrainingClock;
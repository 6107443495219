import React, {useEffect, useState} from 'react';
import {Checkbox, Space, Switch, Table, Tag} from 'antd';
// import {getPopularRank} from "../../config/api";
import {useRequest} from "ahooks";
import {confirmRaffle, getRaffleList} from "../../config/api";
import {constrainedMDSLayout} from "@antv/g2plot/lib/plots/venn/layout/layout";


const RaffleTable = ({raffleList, setRaffleList, loading}) => {
    const onChange = (raffle) => {
        const  {id , confirm} = raffle
        confirmRaffle({id}).then(r => {
            if (r) {
                const newList = raffleList
                const index = raffleList.indexOf(raffle)
                newList[index].confirm = !confirm
                setRaffleList(newList)
                console.log(newList)
            }
        })
    }
    const columns = [
        {
            title: '用户',
            dataIndex: 'mobile',
            key: 'mobile',
            align: 'center',
            render: (mobile) => {
                let newMobile = "**" + mobile?.substr(7)
                return <div>{newMobile}</div>
            }
        },
        {
            title: '奖品',
            dataIndex: 'gift',
            key: 'gift',
            align: 'center',
            render: (gift) => {
                switch (gift) {
                    case 0:
                        return null
                    case 1:
                        return <Tag color="blue">粉团扇藻</Tag>
                    case 2:
                        return <Tag color="green">螺旋藻角</Tag>
                    case 3:
                        return <Tag color="gold">惊喜礼盒</Tag>
                }
            }
        },
        {
            title: '时间',
            dataIndex: 'time',
            key: 'time',
            align: 'center',
            width: 0,
            render: (time) => {
                let date = new Date(time);
                let formattedDate = date.toLocaleTimeString('zh-CN', { hour: 'numeric', minute: 'numeric' });
                return <div>{formattedDate}</div>
            }
        },
        {
            title: '核销',
            dataIndex: 'confirm',
            key: 'confirm',
            align: 'center',
            width: 0,
            render: (confirm, record) => <Checkbox defaultChecked={confirm} onChange={() =>onChange(record)}></Checkbox>
        }
    ];
    return <div className="card" style={{flex: 1, minWidth: 250}}>
        <div className="title">获奖列表</div>
        <Table
            columns={columns}
            dataSource={raffleList}
            loading={loading}
            size="small"
            rowKey="id"
        />
    </div>;
}
export default RaffleTable;
import {Button, DatePicker, Statistic} from "antd";
import {RedoOutlined, SearchOutlined} from "@ant-design/icons";
import React from "react";
import dayjs from "dayjs";

const DateCheck = ({raffleDate, setRaffleDate, getDate}) => {
    const checkDate = (_, e) => {
        setRaffleDate(e)
        getDate(e)
    }
    return  <div style={{display: "flex",justifyContent: "flex-end",gap: 10}}>
        <DatePicker defultValue={dayjs(raffleDate,'YYYY-MM-DD')} value={dayjs(raffleDate,'YYYY-MM-DD')} onChange={checkDate} />
        <Button type="primary" shape="circle" icon={<RedoOutlined />}  onClick={() => getDate(raffleDate)}/>
    </div>
}
export default DateCheck
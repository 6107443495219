import {
    Button,
    Collapse,
    ConfigProvider,
    DatePicker,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Slider,
    Table,
    Radio, Switch, Spin
} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {Radar} from "@antv/g2plot";
import {
    addProductTestRecord,
    deleteRecord, doDeleteRecord, editProduct, editProductTestFeedback,
    editProductTestRecord,
    getProductTestRecord,
    getUserSkinInfo
} from "../../config/api";
import dayjs from "dayjs";
import {useSelector} from "react-redux";

const columns = [
    {
        // title: <EditOutlined/>,
        width: 60,
        align: 'center',
        render: (item, row, index) => {
            if (index === 0) {
                return "额头"
            }
            if (index === 1) {
                return "下巴"
            }
            if (index === 2) {
                return "左脸颊"
            }
            if (index === 3) {
                return "右脸颊"
            }
        }
    },
    {
        title: '水份',
        dataIndex: 's1',
        align: 'center'
    },
    {
        title: '弹性',
        dataIndex: 's2',
        align: 'center'
    },
    {
        title: '油份',
        dataIndex: 's3',
        align: 'center'
    },
    {
        title: '得分',
        dataIndex: 's4',
        align: 'center'
    },
    // {
    //     width: 50,
    //     align: 'center',
    //     render: (item, row, index) => {
    //         return <EditOutlined/>
    //     }
    // }

];

const skinTypeList = [
    "干性",
    "干偏油",
    "混合性",
    "油偏干",
    "油性",
]

const getTableList = (data) => {
    return data?.map((item, index) => {
        return {
            "key": index,
            "s1": item[0],
            "s2": item[1],
            "s3": item[2],
            "s4": item[3]
        }
    })
}

export default () => {

    const [listData, setListData] = useState([])
    const [skinInfo, setSkinInfo] = useState({})
    const [chart, setChart] = useState([])
    const [newFormOpen, setNewFormOpen] = useState(false)
    const [feedbackOpen, setFeedbackOpen] = useState(false)
    const [userSkinInput, setUserSkinInput] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editIndex, setEditIndex] = useState({})
    const [newForm] = Form.useForm();
    const [userSkinForm] = Form.useForm();
    const [editDataForm] = Form.useForm();
    const [feedbackForm] = Form.useForm();
    const {userInfo} = useSelector(state => state.user);
    const [activeId, setActiveId] = useState("")

    const extra = (index) => (
        <div style={{display: "flex", gap: 5, alignItems: "center", marginTop: 5}}>
        </div>
    );

    const deleteRecord = (id) => {
        setLoading(true)
        doDeleteRecord({id}).then(r => {
            setLoading(false)
            message.success("操作成功").then()
            getProductTestRecord({filter: "all"}).then(r => {
                setListData(r.data)
            })
        })
    }

    useEffect(() => {
        getProductTestRecord({filter: "all"}).then(r => {
            setListData(r.data)
        })
        getUserSkinInfo().then(r => {
            if (r === null) {

                return
            }
            setSkinInfo(r)
        })
        newForm.setFieldValue("day", dayjs(new Date()))
    }, [])

    const draw = (item) => {
        setTimeout(() => {
            if (item === undefined || (item?.data?.[0]?.length === 0 && item?.data?.[1]?.length === 0)) {
                return
            }
            let drawData = []
            item?.data[item?.data?.[0]?.length === 0 ? 1 : 0]?.map((p, index) => {
                drawData.push(
                    [
                        {item: "弹性", state: "使用前", score: p[1] === null ? 0 : p[1]},
                        {item: "油份", state: "使用前", score: p[2] === null ? 0 : p[2]},
                        {item: "得分", state: "使用前", score: p[3] === null ? 0 : p[3]},
                        {item: "水份", state: "使用前", score: p[0] === null ? 0 : p[0]},
                        {item: "弹性", state: "使用后", score: item?.data[1]?.[index]?.[1]},
                        {item: "油份", state: "使用后", score: item?.data[1]?.[index]?.[2]},
                        {item: "得分", state: "使用后", score: item?.data[1]?.[index]?.[3]},
                        {item: "水份", state: "使用后", score: item?.data[1]?.[index]?.[0]},
                    ]
                )
            })
            let chartList = []
            drawData.map((i, index) => {
                // let min = 100
                // let max = 0
                // i.map(s => {
                //     if (s.score > max) {
                //         max = s.score
                //     }
                //     if (s.score < min) {
                //         min = s.score
                //     }
                // })

                const radarPlot = new Radar('container' + index + item.id, {
                    data: i,
                    xField: 'item',
                    yField: 'score',
                    seriesField: 'state',
                    meta: {
                        score: {
                            alias: '分数',
                            min: 0,
                            max: 80,
                        },
                    },
                    xAxis: {
                        line: null,
                        tickLine: null,
                        grid: {
                            line: {
                                style: {
                                    lineDash: null,
                                },
                            },
                        },
                    },
                    // 开启面积
                    area: {},
                    // 开启辅助点
                    point: {
                        size: 2,
                    },
                    color: ({ state }) => {
                            if(state === '使用后'){
                                return "#fd8e1a"
                            }
                            return "#6807fe"
                    },
                    width: window.innerWidth < 500 ? 250 : 400,
                    height: window.innerWidth < 500 ? 250 : 400
                });
                chartList.push(radarPlot)
                setChart(chartList)
                radarPlot.render()
            }, 200)

        })
    }

    const editButton = (item, type, index) => {
        return (
            <div>
                <div style={{textAlign: 'center', padding: 50}}>
                    <div style={{marginBottom: 10}}>还未记录数值</div>
                    <Button size="small">立即填写</Button>
                </div>
            </div>
        )
    }

    const table = (item, index) => (
        <div>
            <div style={{display: "flex", gap: 20, flexWrap: "wrap", cursor: "pointer"}}>
                <div style={{flex: 1}}>
                    {
                        (item.data?.[0]?.length !== 0) ?
                            <div>
                                <div style={{display: "flex", paddingBottom: 5}}>
                                    <strong style={{flex: 1}}>使用前 测试数据</strong>
                                    <div>{item.startTime ? dayjs(new Date(item.startTime)).format("YYYY-MM-DD HH:mm:ss") : null}</div>
                                </div>
                                <Table bordered dataSource={getTableList(item.data[0])} columns={columns} size="small"
                                       pagination={false}/>
                            </div>
                            :
                            editButton(item, "before", index)
                    }
                </div>
                <div style={{flex: 1}}>
                    {
                        (item.data?.[1]?.length !== 0) ?
                            <div>
                                <div style={{display: "flex", paddingBottom: 5}}>
                                    <strong style={{flex: 1}}>使用后 测试数据</strong>
                                    <div>{item.endTime ? dayjs(new Date(item.endTime)).format("YYYY-MM-DD HH:mm:ss") : null}</div>
                                </div>
                                <Table bordered dataSource={getTableList(item.data[1])} columns={columns} size="small"
                                       pagination={false}/>
                            </div>
                            :
                            editButton(item, "after", index)
                    }
                </div>
            </div>
            <div style={{marginTop: 20, borderTop: "1px solid #ccc", borderBottom: "1px solid #ccc", padding: "10px 0"}}>
                <div style={{display: "flex"}}>
                    <strong style={{flex: 1}}>使用体验：</strong>
                </div>
                <div>{item.feedback === "" || item.feedback === undefined ? "暂无记录" : item.feedback}</div>
                <strong>备注：</strong>
                <div>{item.remark === "" || item.remark === null ? "暂无记录" : item.remark}</div>
            </div>
            <div
                style={{
                    display: item?.data?.[0]?.length !== 0 || item?.data?.[1]?.length !== 0 ? "flex" : "none",
                    flexWrap: "wrap",
                    marginTop: 20,
                    gap: 20
                }}>
                <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "center", gap: 20}}>
                    <div id={"container0" + item.id}></div>
                    额头
                </div>
                <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "center", gap: 20}}>
                    <div id={"container1" + item.id}></div>
                    下巴
                </div>
                <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "center", gap: 20}}>
                    <div id={"container2" + item.id}></div>
                    左脸颊
                </div>
                <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "center", gap: 20}}>
                    <div id={"container3" + item.id}></div>
                    右脸颊
                </div>
            </div>
        </div>
    );

    const getCollapseItems = (list) => {
        return list.map((item, index) => {
            return {
                label: item.product,
                children: table(item, index),
                extra: extra(index),
            }
        })
    }

    const onSubmitNewForm = (values) => {
        if (activeId === "") {
            addProductTestRecord({
                filter: "all",
                day: values.day.format('YYYY-MM-DD'),
                product: values.product,
            }).then(r => {
                setListData(r.data)
            })
        } else {
            editProduct({
                filter: "all",
                id: activeId,
                day: values.day.format('YYYY-MM-DD'),
                product: values.product,
            }).then(r => {
                setListData(r.data)
                setActiveId("")
            })
        }
        setNewFormOpen(false)
    }

    const onSubmitFeedbackForm = (values) => {
        setLoading(true)
        setFeedbackOpen(false)
        editProductTestFeedback({...values, id: activeId, filter: "all"}).then(r => {
            setLoading(false)
            setActiveId("")
            setListData(r.data)
        })
    }


    const onSubmitUserSkinInfo = (values) => {
        console.log(values)
        setUserSkinInput(false)
    }

    const submitEdit = (e) => {
        let data = [
            [e.a1, e.a2, e.a3, e.a4],
            [e.b1, e.b2, e.b3, e.b4],
            [e.c1, e.c2, e.c3, e.c4],
            [e.d1, e.d2, e.d3, e.d4],
        ]

        editProductTestRecord({...editIndex, data, filter: "all"}).then(r => {
            setListData(r.data)
            chart.map(item => item.destroy())
            draw(r.data[editIndex.index])
            setEditIndex({})
        })
    }

    return (
        <>
            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                <Spin spinning={loading}>
                    <Collapse accordion items={getCollapseItems(listData)} destroyInactivePanel
                              onChange={e => draw(listData[e[0]])} collapsible="header" />
                </Spin>
            </div>
        </>
    )
}
import React, {useEffect, useState} from "react";
import DigitalCard from "./digitalCard";
import RaffleTable from "./raffleTable";
import {Column} from "@ant-design/plots";
import DateCheck from "./dateCheck";
import {useRequest} from "ahooks";
import {getRaffleDigital, getRaffleList} from "../../config/api";
import dayjs from "dayjs";

const giftPool = [50, 50, 50, 5]
const PrizeManagement = () => {

    const [raffleDate, setRaffleDate] = useState(dayjs(new Date()).format().slice(0, 10))
    const [raffleList, setRaffleList] = useState([])
    const [digitalData, setDigitalData] = useState({giftList: [],userCount: 0, giftCount :0})
    const {runAsync, loading} = useRequest(getRaffleList, {manual: true});
    const {runAsync: getRD, loading: getRDLoading} = useRequest(getRaffleDigital, {manual: true});
    useEffect(() => {
        getDate(raffleDate)
    }, [])
    const getDate = (e) => {
        runAsync({date: e}).then(r => setRaffleList(r))
        getRD({date: e}).then(r => setDigitalData(r) )
    }
    const getGraphData = (data) => {
        return data?.map ((item,index) => {
            switch (index) {
                case 0: return {type: '谢谢参与', sales: giftPool[0] - item}
                case 1: return {type: '粉团扇藻', sales: giftPool[1] - item}
                case 2: return {type: '螺旋藻角', sales: giftPool[2] - item}
                case 3: return {type: '惊喜礼盒', sales: giftPool[3] - item}
            }
        })
    }
        const data = [
            {
                type: '谢谢参与',
                sales: 25,
            },
            {
                type: '粉团扇藻',
                sales: 17,
            },
            {
                type: '螺旋藻角',
                sales: 32,
            },
            {
                type: '惊喜礼盒',
                sales: 2,
            },
        ];
        const config = {

            xField: 'type',
            yField: 'sales',
            label: {
                // 可手动配置 label 数据标签位置
                position: 'middle',
                // 'top', 'bottom', 'middle',
                // 配置样式
                style: {
                    fill: '#fff',
                    opacity: 0.6,
                },
                ShapeAttrs: {
                    min: 0,
                    max: 50
                },
            },
            yAxis: {
                tickCount:5,
                max: 60
            },
            meta: {
                type: {
                    alias: '类别',identity: 20
                },
                sales: {
                    alias: '销售额',identity: 20
                },
            },
        }

    return (
        <div style={{display: "flex", flexDirection: "column", gap: 20}}>
            <DateCheck raffleDate={raffleDate} setRaffleDate={setRaffleDate} getDate={getDate}/>
            <DigitalCard data={digitalData} giftPool={giftPool}/>
            {/*<PrizeDetailGraph/>*/}
            <div style={{display: "flex", flexWrap: "wrap", gap: 20}}>
                <RaffleTable raffleList={raffleList} setRaffleList={setRaffleList} loading={loading}/>
            </div>
            <div className="card" style={{gap: 20, minWidth: 250}}>
                <div className="title">奖品剩余统计</div>
                <Column {...config}  data={getGraphData(digitalData?.giftList)} />
            </div>
        </div>
    );
}

export default PrizeManagement;

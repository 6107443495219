import React, {useLayoutEffect} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider, useDispatch} from "react-redux";
import {BrowserRouter, useRoutes} from "react-router-dom";
import {initAxios} from "./config/request";
import {routes} from "./config/routes";
import index from "./store";
import {ConfigProvider, App} from "antd";
import zhCN from 'antd/locale/zh_CN';

import "./css/index.css"
import "./common/css/index.css"
import Content from "./config/content";

const Page = () => {
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        initAxios(dispatch);
    }, [dispatch]);
    return useRoutes(routes)
}

const root = createRoot(document.getElementById('root'));

root.render(
    <Provider store={index}>
        <BrowserRouter>
            <React.StrictMode>
                <ConfigProvider theme={{token: {colorPrimary: Content.primaryColor}}} locale={zhCN}>
                    <App>
                        <Page/>
                    </App>
                </ConfigProvider>
            </React.StrictMode>
        </BrowserRouter>
    </Provider>
);

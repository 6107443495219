const Content = {
    title: " 两两 LeanOn",
    company: "2021-2023 ULink Co.",
    icp: "沪ICP2023002085号",
    logoUrl: "https://gw.alipayobjects.com/zos/antfincdn/FLrTNDvlna/antv.png",

    primaryColor: "#597ef7",

    fileServiceUrl: "https://zxt.sh.cn",
    // fileServiceUrl: "https://139.224.23.238",
}

export default Content;

import React, { useEffect, useState, useMemo } from "react";
import { Radar } from '@antv/g2plot';
import { Button, Input, Tree } from "antd";

const x = 3;
const y = 2;
const z = 1;
const defaultData = [];
const generateData = (_level, _preKey, _tns) => {
    const preKey = _preKey || '0';
    const tns = _tns || defaultData;
    const children = [];
    for (let i = 0; i < x; i++) {
        const key = `${preKey}-${i}`;
        tns.push({
            title: key,
            key,
        });
        if (i < y) {
            children.push(key);
        }
    }
    if (_level < 0) {
        return tns;
    }
    const level = _level - 1;
    children.forEach((key, index) => {
        tns[index].children = [];
        return generateData(level, key, tns[index].children);
    });
};
generateData(z);
const dataList = [];
const generateList = (data) => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const { key } = node;
        dataList.push({
            key,
            title: key,
        });
        if (node.children) {
            generateList(node.children);
        }
    }
};
generateList(defaultData);
const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some((item) => item.key === key)) {
                parentKey = node.key;
            } else if (getParentKey(key, node.children)) {
                parentKey = getParentKey(key, node.children);
            }
        }
    }
    return parentKey;
};

const Collection = () => {

    const [activeId, setActiveId] = useState([]);

    const [data, setData] = useState([
        {
            title: "RU",
            children: [
                {
                    title: "芳香疗愈",
                    children: [
                        {
                            title: "补水保湿",
                            children: [
                                {
                                    title: "20230922-1",
                                    key: "MM01-01-20230920-ZMX-1"
                                },
                                {
                                    title: "20230918",
                                    key: "MM01-01-20230902-ZMX"
                                },
                            ]
                        }
                    ]
                },
            ]
        }
    ])

    return (
        <>

            <div style={{ display: "flex", gap: 20 }}>
                <div className="card" style={{ width: 200 }}>
                    <div>
                        <Button block type="primary" style={{ marginBottom: 10 }}>新增测试计划</Button>
                        <Input.Search
                            style={{
                                marginBottom: 8,
                            }}
                            placeholder="Search"
                        />
                        <Tree
                        onSelect={e => setActiveId(e)}
                            treeData={data}
                        />
                    </div>
                </div>
                <div className="card" style={{ flex: 1 }}>
                    {activeId[0]}
                </div>
            </div>
        </>
    )
}

export default Collection;
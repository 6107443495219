import {Col, Row, Statistic,Button} from "antd";
import React, {useEffect, useState} from "react";
import CountUp from 'react-countup'
import {useRequest} from "ahooks";
import {SearchOutlined} from "@ant-design/icons";
import {getRaffleDigital} from "../../config/api";

const formatter = (value) =>  <CountUp end={value} separator=","/>;
const DigitalCard = ({data, giftPool}) => {

    return (
        <div className="card" style={{minWidth: 250}}>
            <div className="title">奖品数据</div>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                <div style={{display: "flex", flex:1, minWidth: 250}}>
                    <Statistic style={{flex: 1}} title="参与人数" value={data.userCount} suffix={<div style={{fontSize: 16}}>/ {
                        giftPool.reduce((accumulator, currentValue) => {
                            return accumulator + currentValue;
                        }, 0)
                    }</div>} formatter={formatter}/>
                    <Statistic style={{flex: 1}} title="奖品剩余" value={giftPool.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue;
                    }, 0) - giftPool[0] - data.giftCount} suffix={<div style={{fontSize: 16}}>/ {
                        giftPool.reduce((accumulator, currentValue) => {
                            return accumulator + currentValue;
                        }, 0) - giftPool[0]
                    }</div>} formatter={formatter}/>
                </div>
            </div>
        </div>
    )

}
export default DigitalCard
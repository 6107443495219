import React, {useState} from "react";
import {Button, Form, Input, Modal, Table, Tag} from "antd";
import {FormOutlined} from "@ant-design/icons";

const Training = () => {

    const columns = [
        {
            title: '堂训ID',
            dataIndex: 'id',
            width: 100,
            align: "center"
        },
        {
            title: '名称',
            dataIndex: 'name',
        },
        {
            title: '地点',
            dataIndex: 'location',
            width: 80,
            align: "center"
        },
        {
            title: '时间',
            dataIndex: 'date',
            width: 120,
            align: "center"
        },
        {
            title: '状态',
            render: (_, row) => row.id === "100003" ? <Tag color="gold">进行中</Tag> : <Tag>已结束</Tag>,
            width: 80,
            align: "center"
        },
        {
            title: '操作',
            render: (_, row) => <div style={{display: "flex", gap: 20}}><div>删除</div><div>暂停</div></div>,
            width: 120,
            fixed: 'right',
            align: "center"
        },
    ];
    const data = [
        {id: "100003", name: "两两六月堂训", location: "上海", date: "2023-06-24"},
        {id: "100002", name: "两两六月堂训", location: "长沙", date: "2023-06-22"},
        {id: "100001", name: "两两六月堂训", location: "武汉", date: "2023-06-20"},
        // {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const formRef = React.useRef(null);

    const onFinish = (values) => {
        console.log(values);
    };

    const onReset = () => {
        formRef.current?.resetFields();
    };

    const onFill = () => {
        formRef.current?.setFieldsValue({
            note: 'Hello world!',
            gender: 'male',
        });
    };


    return (
        <div style={{display: "flex", flexDirection: "column", gap: 20}}>
            <div className="card" style={{flexDirection: "initial"}} onClick={() => setIsModalOpen(true)}>
                <div style={{fontSize: 16, fontWeight: 800, whiteSpace: "nowrap", flex: 1}}>新增堂训计划</div>
                <FormOutlined style={{color: "gray", fontSize: 16}}/>
            </div>
            <div className="card">
                <div style={{display: "flex", alignItems: "end"}}>
                    <div style={{fontSize: 16, fontWeight: 800, whiteSpace: "nowrap", flex: 1}}>堂训记录</div>
                    <div style={{whiteSpace: "nowrap"}}>总计: 2 条</div>
                </div>
                <Table columns={columns} dataSource={data} scroll={{x: 650}}/>
            </div>
            <Modal title="新增堂训计划" open={isModalOpen} onCancel={handleCancel} footer={null}>
                <Form onFinish={onFinish}>

                    <Form.Item
                        label="名称"
                        name="name"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="地点"
                        name="location"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="时间"
                        name="date"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item >
                        <div style={{display: "flex", gap: 10, alignItems: "center"}}>
                            <Button type="primary" htmlType="submit" style={{ flex: 1}}>
                                提交
                            </Button>
                            <Button htmlType="button" onClick={onReset}>
                                重置
                            </Button>
                            <Button type="link" htmlType="button" onClick={onFill} size="small">
                                自动填充
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Training;
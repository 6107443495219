import {request} from "./request";

export const getUserList = async body => request("training/admin/gifting", body);
export const getClockRecord = async body => request("training/admin/clockRecord", body);

export const getNetDiskList = async body => request("netDisk/getNetDiskList", body);
export const netDiskOperate = async body => request("netDisk/operate", body);
export const getDownloadCode = async body => request("netDisk/getDownloadCode", body);
export const getDeleteCode = async body => request("netDisk/deleteFile", body);
export const getProductTestRecord = async body => request("product/getRecord", body);
export const addProductTestRecord = async body => request("product/addRecord", body);
export const editProductTestRecord = async body => request("product/editRecord", body);
export const getUserSkinInfo = async body => request("product/getUserSkinInfo", body);
export const doDeleteRecord = async body => request("product/deleteRecord", body);
export const editProductTestFeedback = async body => request("product/editFeedback", body);
export const editProduct = async body => request("product/editProduct", body);
export const getRaffleList = async body => request("raffle/list", body);
export const confirmRaffle = async body => request("raffle/confirm", body);
export const getRaffleDigital = async body => request("raffle/digital", body);
